import classes from "./RelatedArticles.module.css";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { scrollToTop } from "../../Utilities/scrollToTop";

const RelatedArticles = ({ moreLikeThis }) => {
  // Navigate
  const navigate = useNavigate();

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.topStoriesHeader}>
          <div>Related Articles</div>
        </div>
      </div>

      {moreLikeThis?.length < 1 ? (
        <div>
          <CircularProgress
            size="1rem"
            color="inherit"
            style={{ color: "#ffd91b" }}
          />
        </div>
      ) : (
        <div className={classes.newsContainer}>
          <div className={classes.newsContainerInner}>
            <div className={classes.topStories}>
              <div className={classes.homeStoriesRight}>
                {moreLikeThis?.map((headline) => {
                  return (
                    <div
                      className={classes.headlineRight}
                      key={headline?.uri}
                      onClick={() => {
                        navigate(
                          `/home/${headline.uri}/${headline?.headline
                            .replaceAll(" ", "-")
                            .toLowerCase()}`,
                          { replace: true }
                        );
                        scrollToTop();
                      }}
                    >
                      <div className={classes.description}>
                        <div>{headline?.headline}</div>
                        <div>
                          {"By SportsDM"} •{" "}
                          {moment(headline.firstcreated).fromNow()}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RelatedArticles;
