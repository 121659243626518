import { useContext } from "react";
import ReusableArticlesContainer from "../../Components/ReusableArticlesContainer/ReusableArticlesContainer";
import { AppContext } from "../../Context/AppContext";

const TopStoriesContainer = () => {
  // context

  const { topStories, isSendingRequest, fetchTopStories } =
    useContext(AppContext);
  return (
    <div>
      <ReusableArticlesContainer
        articles={topStories}
        title="Top Stories"
        isSendingRequest={isSendingRequest}
        fetchFunction={fetchTopStories}
      />
    </div>
  );
};

export default TopStoriesContainer;
