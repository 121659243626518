import { createContext, useState } from "react";
import axios from "axios";
import moment from "moment/moment";

export const MatchesContext = createContext();

const MatchesContextProvider = (props) => {
  //   Variables
  const requiredDate = moment().format(moment.HTML5_FMT.DATE);
  const formattedDate = moment(requiredDate).format(moment.HTML5_FMT.DATE);
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  // States
  const [loadingLeagueMatches, setLoadingLeagueMatches] = useState(false);
  const [allMatchesFromApi, setAllMatchesFromApi] = useState([]);
  const [includeLive] = useState(false);

  const includeLiveQueryParam = includeLive ? "&status_type=inprogress" : "";

  // Fetches
  const getAllLeaguesANdEventsAlt = async () => {
    setLoadingLeagueMatches(true);
    try {
      const res = await axios.get(
        `https://eapi.enetpulse.com/tournament_template/list/?sportFK=1&username=${process.env.REACT_APP_ENETPULSE_USERNAME}&token=${process.env.REACT_APP_ENETPULSE_TOKEN_ID}&tz=${timeZone}`
      );

      // Fetch league matches for all events
      const allResponses = [];

      const promises = Object.values(res.data.tournament_templates).map(
        (league) => {
          return axios
            .get(
              `https://eapi.enetpulse.com/event/daily/?date=${formattedDate}${includeLiveQueryParam}&live=yes&includeVenue=yes&includeEventProperties=yes&includeCountryCodes=no&tz=${timeZone}&tournament_templateFK=${league.id}&username=${process.env.REACT_APP_ENETPULSE_USERNAME}&token=${process.env.REACT_APP_ENETPULSE_TOKEN_ID}&tz=${timeZone}`
            )
            .then((response) => {
              if (Object.values(response.data.events).length > 0) {
                return response.data.events;
              } else {
                return {};
              }
            })
            .catch((error) => {});
        }
      );

      try {
        const responses = await Promise.all(promises);
        // Add all the responses to the allResponses array
        setLoadingLeagueMatches(false);

        allResponses.push(...responses);
      } catch (error) {
        setLoadingLeagueMatches(false);
      }

      const filteredResponses = allResponses.filter((data) => {
        return Object.keys(data).length > 0;
      });

      // }

      const test = filteredResponses.map((data) => {
        return Object.values(data);
      });

      setAllMatchesFromApi(test.flat());
    } catch (err) {
      setLoadingLeagueMatches(false);
    }
  };

  return (
    <MatchesContext.Provider
      value={{
        allMatchesFromApi,
        loadingLeagueMatches,
        getAllLeaguesANdEventsAlt,
      }}
    >
      {props.children}
    </MatchesContext.Provider>
  );
};

export default MatchesContextProvider;
