import Layout from "../../Components/Layout/Layout";
import classes from "../TransfersSection/TransfersSection.module.css";
import moment from "moment/moment";
import { useContext, useEffect } from "react";
import { AppContext } from "../../Context/AppContext";
import { useNavigate } from "react-router-dom";
import Card from "../../Components/Card/Card";
import AdComponent from "../../Components/AdComponent/AdComponent";
import { CircularProgress } from "@mui/material";

const ArticlesPage = () => {
  // context
  const { articles, fetchArticles, isSendingRequest } = useContext(AppContext);

  // Navigate
  const navigate = useNavigate();

  // Effects
  useEffect(() => {
    fetchArticles();

    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <section className={classes.container}>
        <div className={classes.header}>
          <div className={classes.topStoriesHeader}>
            <div>Articles</div>
          </div>
        </div>
        {isSendingRequest ? (
          <div className={classes.loading}>
            <CircularProgress
              size="1rem"
              color="inherit"
              style={{ color: "#ffd91b" }}
            />
          </div>
        ) : (
          <div className={classes.newsContainer}>
            <Card>
              <div className={classes.newsContainerInner}>
                <div className={classes.homeStoriesLeft}>
                  <div className={classes.homeStoriesLeftTop}>
                    {articles?.slice(0, 1)?.map((headline) => {
                      return (
                        <div
                          className={classes.headlineRight}
                          key={headline?.uri}
                          onClick={() => {
                            navigate(`/home/${headline.uri}`);
                          }}
                        >
                          <div className={classes.imageSection}>
                            <img
                              src={headline?.picture}
                              alt={headline?.headline}
                            />
                          </div>
                          <div className={classes.description}>
                            <div>
                              {headline?.headline?.length > 50
                                ? `${headline?.headline?.slice(0, 50)}...`
                                : headline?.headline}
                            </div>
                            <div>
                              {"By SportsDM"} •{" "}
                              {moment(headline.firstcreated).fromNow()}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className={classes.homeStoriesLeftBottom}>
                    {articles?.slice(1, 3)?.map((headline) => {
                      return (
                        <div
                          className={classes.headlineRight}
                          key={headline?.uri}
                          onClick={() => {
                            navigate(`/home/${headline.uri}`);
                          }}
                        >
                          <div className={classes.imageSection}>
                            <img
                              src={headline?.picture}
                              alt={headline?.headline}
                            />
                          </div>
                          <div className={classes.description}>
                            <div>{headline?.headline}</div>
                            <div>
                              {"By SportsDM"} •{" "}
                              {moment(headline.firstcreated).fromNow()}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className={classes.homeStoriesRight}>
                  {articles?.slice(3, 5)?.map((headline) => {
                    return (
                      <div
                        className={classes.headlineRight}
                        key={headline?.uri}
                        onClick={() => {
                          navigate(`/home/${headline.uri}`);
                        }}
                      >
                        <div className={classes.imageSection}>
                          <img
                            src={headline?.picture}
                            alt={headline?.headline}
                          />
                        </div>
                        <div className={classes.description}>
                          <div>
                            {headline?.headline?.length > 40
                              ? `${headline?.headline?.slice(0, 40)}...`
                              : headline?.headline}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={classes.homeStoriesBottomBottom}>
                <div className={classes.homeStoriesBottomBottomLeft}>
                  {articles?.slice(5)?.map((headline) => {
                    return (
                      <div
                        className={classes.headlineRight}
                        key={headline?.uri}
                        onClick={() => {
                          navigate(`/home/${headline.uri}`);
                        }}
                      >
                        <div className={classes.imageSection}>
                          <img
                            src={headline?.picture}
                            alt={headline?.headline}
                          />
                        </div>
                        <div className={classes.description}>
                          <div>{headline?.headline}</div>
                          <div>
                            {"By SportsDM"} •{" "}
                            {moment(headline.firstcreated).fromNow()}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className={classes.homeStoriesBottomBottomRight}>
                  <div>
                    <AdComponent height="484px" width="244px" />
                  </div>

                  <div>
                    <AdComponent height="484px" width="244px" />
                  </div>
                </div>
              </div>
            </Card>
          </div>
        )}
      </section>
    </Layout>
  );
};

export default ArticlesPage;
