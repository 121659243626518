import moment from "moment/moment";
import { useContext } from "react";
import { AppContext } from "../../Context/AppContext";
import { useNavigate } from "react-router-dom";
import classes from "./HomeTransferNewsContainer.module.css";
import { scrollToTop } from "../../Utilities/scrollToTop";

const HomeBasketBallNewsContainer = () => {
  // context
  const { createdNews } = useContext(AppContext);

  // Navigate
  const navigate = useNavigate();

  return (
    <div className={classes.container}>
      <div className={classes.homeStoriesRight}>
        {createdNews?.slice(0, 1)?.map((headline) => {
          return (
            <div
              className={classes.headlineRight}
              key={headline?.id}
              onClick={() => {
                navigate(
                  `/home/created-for-you/${headline.id}/${headline?.slug}`
                );
                scrollToTop();
              }}
            >
              <div className={classes.imageSection}>
                <img src={headline?.image[0]} alt={headline?.title} />
                <div className={classes.tag}>{"Football"}</div>
              </div>
              <div className={classes.description}>
                <div>
                  {"By SportsDM"} • {moment(headline.updated_at).fromNow()}
                </div>
                <div>
                  {" "}
                  {headline?.title?.length > 50
                    ? `${headline?.title?.slice(0, 90)}...`
                    : headline?.title}
                </div>
                <a
                  href={`/home/created-for-you/${headline.id}/${headline?.slug}`}
                >
                  Continue Reading
                </a>
              </div>
            </div>
          );
        })}
      </div>

      <div className={classes.homeStoriesLeft}>
        {createdNews?.slice(1, 4)?.map((headline) => {
          return (
            <div
              className={classes.headlineLeft}
              key={headline?.id}
              onClick={() => {
                navigate(
                  `/home/created-for-you/${headline.id}/${headline?.slug}`
                );
                scrollToTop();
              }}
            >
              <div className={classes.imageSection}>
                <img
                  src={headline?.picture || headline?.image[0]}
                  alt={headline.headline}
                />
                <div className={classes.tag}>{"Football"}</div>
              </div>
              <div className={classes.description}>
                <div>
                  {headline?.title?.length > 50
                    ? `${headline?.title?.slice(0, 50)}...`
                    : headline?.title}
                </div>
                <div>
                  {" "}
                  {headline?.short_description?.length > 90
                    ? `${headline?.short_description?.slice(0, 90)}...`
                    : headline?.short_description}
                </div>
                <a
                  href={`/home/created-for-you/${headline.id}/${headline?.slug}`}
                >
                  Continue Reading
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HomeBasketBallNewsContainer;
