import React, { useContext } from "react";
import ReusableArticlesContainer from "../../Components/ReusableArticlesContainer/ReusableArticlesContainer";
import { AppContext } from "../../Context/AppContext";

const ChampionsLeagueContainer = () => {
  // context
  const {
    championsLeagueNews,
    isSendingRequest,
    fetchChampionsLeagueNewsHandler,
    setChampionsLeagueOffset,
  } = useContext(AppContext);

  return (
    <div>
      <ReusableArticlesContainer
        articles={championsLeagueNews}
        title="Champions League News"
        isSendingRequest={isSendingRequest}
        fetchFunction={fetchChampionsLeagueNewsHandler}
        setOffset={setChampionsLeagueOffset}
      />
    </div>
  );
};

export default ChampionsLeagueContainer;
