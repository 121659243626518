import Layout from "../../Components/Layout/Layout";
import classes from "./FixturesContainer.module.css";
import AdComponent from "../../Components/AdComponent/AdComponent";
import FixturesMatchContainer from "./FixturesMatchContainer";
import Card from "../../Components/Card/Card";

const FixturesContainer = () => {
  return (
    <Layout>
      <div className={classes.outerContainer}>
        <div className={classes.header}>
          <div className={classes.topStoriesHeader}>
            <div>Fixtures</div>
          </div>
        </div>
        <div className={classes.container}>
          <div className={classes.leftSection}>
            <Card>
              <div className={classes.fixturesContainer}>
                <FixturesMatchContainer />
              </div>
            </Card>
          </div>
          <div className={classes.rightSection}>
            <div className={classes.adSection}>
              <AdComponent height="828px" width="280px" />
            </div>

            <div className={classes.adSection}>
              <AdComponent height="828px" width="280px" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FixturesContainer;
