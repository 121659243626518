import React, { useContext } from "react";
import ReusableArticlesContainer from "../../Components/ReusableArticlesContainer/ReusableArticlesContainer";
import { AppContext } from "../../Context/AppContext";

const ToodayLastYearStoriesContainer = () => {
  // context
  const { thisDayLastYear, isSendingRequest, fetchThisDayLastYear } =
    useContext(AppContext);

  return (
    <div>
      <ReusableArticlesContainer
        articles={thisDayLastYear}
        title="Stories from this day Last Year"
        isSendingRequest={isSendingRequest}
        fetchFunction={fetchThisDayLastYear}
        isCreated={false}
      />
    </div>
  );
};

export default ToodayLastYearStoriesContainer;
