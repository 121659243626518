import React from "react";
import classes from "./Footer.module.css";
import sportdmLogo from "../../Assets/Images/sportdmLogo.png";
import { Link } from "react-router-dom";
import { v4 } from "uuid";
import { SocialIcon } from "react-social-icons";
import { scrollToTop } from "../../Utilities/scrollToTop";

const Footer = () => {
  const footerLinks = [
    { id: v4(), title: "About Us", route: "/about-us" },
    { id: v4(), title: "Advertise", route: "/advertise" },
    { id: v4(), title: "Privacy Notice", route: "/privacy-notice" },
    { id: v4(), title: "Cookie Policy", route: "/cookie-policy" },
  ];
  return (
    <div className={classes.container}>
      <div className={classes.socialSection}>
        <div className={classes.header}>Follow SportDM</div>
        <div className={classes.socialicons}>
          <span>
            <SocialIcon
              url="https://chat.whatsapp.com/DyQ0xdTZnlUHgBndpPqsdi"
              bgColor="#FFFFFF"
              style={{ width: "25px", height: "25px" }}
            />
          </span>
          <span>
            <SocialIcon
              url="https://www.instagram.com/thesportdm?igsh=YzVkODRmOTdmMw=="
              fgColor="#FFFFFF"
              bgColor="#000000"
              style={{ width: "40px", height: "40px" }}
            />
          </span>{" "}
          <span>
            <SocialIcon
              url="https://twitter.com/Sport__Dm?t=_U5pMvVBRWyGK5jsa6qBRQ&s=09"
              fgColor="#FFFFFF"
              bgColor="#000000"
              style={{ width: "40px", height: "40px" }}
            />
          </span>
        </div>

        <div className={classes.footerBottom}>
          <div className={classes.logoSection}>
            <img src={sportdmLogo} alt="FlexMag Logo" />

            <span>SPORTS AND CULTURE</span>
          </div>
          <div>&copy; 2023 Sports DM</div>
          <div className={classes.footerLinks}>
            {footerLinks.map((data) => {
              return (
                <Link to={data.route} key={data.id} onClick={scrollToTop}>
                  {data.title}
                </Link>
              );
            })}
          </div>
        </div>
      </div>
      <div className={classes.circlrFilter}></div>
    </div>
  );
};

export default Footer;
