import React from "react";
import { useNavigate } from "react-router-dom";
import Card from "../../Components/Card/Card";
import classes from "./HomeTopStoriesAndBreakingNews.module.css";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { scrollToTop } from "../../Utilities/scrollToTop";
import HomeBaseballContainer from "./HomeBaseballContainer";
import HomeCompetition from "./HomeCompetitionNews";

const HomeGolfAndBaseBall = () => {
  // Navigation
  const navigate = useNavigate();

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div
          className={classes.topStoriesHeader}
          onClick={() => {
            navigate("/afcon");
          }}
        >
          <div> AFCON</div>
          <div className={classes.navIcon}>
            <FontAwesomeIcon icon={faAngleRight} />
          </div>
        </div>
        <div
          className={classes.breakingNewsHeader}
          onClick={() => {
            navigate("/afcon");
          }}
        >
          <div>AFCON</div>
          <div className={classes.navIcon}>
            <FontAwesomeIcon icon={faAngleRight} />
          </div>
        </div>
      </div>
      <div className={classes.newsContainer}>
        <Card>
          <div className={classes.newsContainerInner}>
            <div className={classes.topStories}>
              <HomeCompetition />
            </div>
            <div>
              <HomeBaseballContainer />
            </div>
          </div>
        </Card>
      </div>

      <div className={classes.mobileNewsContainer}>
        <div className={`${classes.header} ${classes.mobileSecondHeader}`}>
          <div
            className={classes.topStoriesHeader}
            onClick={() => {
              navigate("/afcon");
              scrollToTop();
            }}
          >
            <div>AFCON</div>
            <div className={classes.navIcon}>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </div>
        </div>
        <Card>
          <div className={classes.newsContainerInner}>
            <HomeBaseballContainer />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default HomeGolfAndBaseBall;
