import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./Containers/Home/Home";
import ArticleContainer from "./Containers/ArticleContainer/ArticleContainer";
import ArticlesPage from "./Containers/ArticlesPage/ArticlesPage";
import TransfersSection from "./Containers/TransfersSection/TransfersSection";
import SearchContainer from "./Containers/SearchContainer/SearchContainer";
import FixturesContainer from "./Containers/FixturesContainer/FixturesContainer";
import TopStoriesContainer from "./Containers/TopStoriesContainer/TopStoriesContainer";
import BreakingNewsContainer from "./Containers/BreakingNewsContainer/BreakingNewsContainer";
import ToodayLastYearStoriesContainer from "./Containers/TodayLastYearStoriesContainer/TodayLastYearStoriesContainer";
import BasketBallContainer from "./Containers/BasketBallContainer/BasketBallContainer";
import GolfContainer from "./Containers/GolfContainer/GolfContainer";
import BaseballContainer from "./Containers/BaseballContainer/BaseballContainer";
import ChampionsLeagueContainer from "./Containers/ChampionsLeagueContainer/ChampionsLeagueContainer";
import WomenSportNewsContainer from "./Containers/WomenSportNewsContainer/WomenSportNewsContainer";
import AboutUs from "./Containers/AboutUs/AboutUs";
import PrivacyPolicy from "./Containers/PrivacyPolicy/PrivacyPolicy";
import CookiePolicy from "./Containers/CookiePolicy/CookiePolicy";
import Advertise from "./Containers/Advertise/Advertise";

function App() {
  // Location utils
  const ghanaBox = {
    latMin: 4.5,
    latMax: 11,
    lonMin: -3,
    lonMax: 1.5,
  };

  const kenyaBox = {
    latMin: -4.5,
    latMax: 4,
    lonMin: 33,
    lonMax: 42,
  };

  //  get user location
  useEffect(() => {
    navigator.geolocation.getCurrentPosition((location) => {
      const { latitude, longitude } = location.coords;

      if (
        latitude >= ghanaBox.latMin &&
        latitude <= ghanaBox.latMax &&
        longitude >= ghanaBox.lonMin &&
        longitude <= ghanaBox.lonMax &&
        window.location.href !== "https://gh.sportdm.com"
      ) {
        window.location.replace("https://gh.sportdm.com");
      } else if (
        latitude >= kenyaBox.latMin &&
        latitude <= kenyaBox.latMax &&
        longitude >= kenyaBox.lonMin &&
        longitude <= kenyaBox.lonMax &&
        window.location.href !== "https://ke.sportdm.com"
      ) {
        window.location.replace("https://ke.sportdm.com");
      } else return;
    });

    // eslint-disable-next-line
  }, []);

  return (
    <Routes>
      <Route path="/articles" element={<ArticlesPage />} />
      <Route path="/transfers" element={<TransfersSection />} />
      <Route path="/fixtures" element={<FixturesContainer />} />
      <Route path="/search" element={<SearchContainer />} />
      <Route path="/home/top-stories" element={<TopStoriesContainer />} />
      <Route path="/home/breaking-news" element={<BreakingNewsContainer />} />
      <Route path="/" element={<Navigate replace to="/home" />} />
      <Route path="/home" element={<Home />} />
      <Route path="/home/:articleId/:slug" element={<ArticleContainer />} />
      <Route
        path="/home/created-for-you/:articleId/:slug"
        element={<ArticleContainer />}
      />
      <Route path="/articles" element={<ArticlesPage />} />
      <Route path="/transfers" element={<TransfersSection />} />
      <Route path="/fixtures" element={<FixturesContainer />} />
      <Route path="/search" element={<SearchContainer />} />
      <Route path="/home/top-stories" element={<TopStoriesContainer />} />
      <Route path="/home/breaking-news" element={<BreakingNewsContainer />} />

      <Route
        path="/home/this-day-last-year"
        element={<ToodayLastYearStoriesContainer />}
      />

      <Route path="/news-for-you" element={<BasketBallContainer />} />
      <Route path="/afcon" element={<GolfContainer />} />
      <Route path="/afcon" element={<BaseballContainer />} />
      <Route path="/champions-league" element={<ChampionsLeagueContainer />} />
      <Route path="/women-sport-news" element={<WomenSportNewsContainer />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/privacy-notice" element={<PrivacyPolicy />} />
      <Route path="/cookie-policy" element={<CookiePolicy />} />
      <Route path="/advertise" element={<Advertise />} />
    </Routes>
  );
}

export default App;
