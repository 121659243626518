import HomeDateSection from "./HomeDateSection";
import classes from "./HomeLiveMatch.module.css";
import HomeLiveMatchContainer from "./HomeLiveMatchContainer";

const HomeLiveMatch = () => {
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <HomeDateSection />
      </div>
      <div className={classes.body}>
        <HomeLiveMatchContainer />
      </div>
    </div>
  );
};

export default HomeLiveMatch;
