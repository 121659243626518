import classes from "./MoreLikeThisNewsContainer.module.css";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../Utilities/scrollToTop";

const MoreLikeThisNewsContainer = ({ moreLikeThisItems }) => {
  // Navigate
  const navigate = useNavigate();
  return (
    <div className={classes.container}>
      <div className={classes.homeStoriesRight}>
        {moreLikeThisItems?.map((headline) => {
          return (
            <div
              className={classes.headlineRight}
              key={headline?.uri}
              onClick={() => {
                navigate(
                  `/home/${headline.uri}/${headline?.headline
                    .replaceAll(" ", "-")
                    .toLowerCase()}`,
                  { replace: true }
                );
                scrollToTop();
              }}
            >
              <div className={classes.imageSection}>
                <img src={headline?.picture} alt={headline?.headline} />
              </div>
              <div className={classes.description}>
                <div>
                  {headline?.headline?.length > 50
                    ? `${headline?.headline?.slice(0, 50)}...`
                    : headline?.headline}
                </div>
                <div>
                  {"By SportsDM"} • {moment(headline.firstcreated).fromNow()}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MoreLikeThisNewsContainer;
