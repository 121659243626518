import React, { useContext } from "react";
import ReusableArticlesContainer from "../../Components/ReusableArticlesContainer/ReusableArticlesContainer";
import { AppContext } from "../../Context/AppContext";

const BasketBallContainer = () => {
  // context
  const { createdNews, isSendingRequest, getCreatedNews } =
    useContext(AppContext);

  return (
    <div>
      <ReusableArticlesContainer
        articles={createdNews}
        title="News for you"
        isSendingRequest={isSendingRequest}
        fetchFunction={getCreatedNews}
        isCreated={true}
      />
    </div>
  );
};

export default BasketBallContainer;
