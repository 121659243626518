import { useContext } from "react";
import { AppContext } from "../../Context/AppContext";
import classes from "./HomeTopStories.module.css";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../Utilities/scrollToTop";

const HomeTopStories = () => {
  // context
  const { topStories } = useContext(AppContext);

  // Navigate
  const navigate = useNavigate();
  return (
    <div className={classes.container}>
      <div className={classes.homeStoriesLeft}>
        {topStories?.slice(0, 3)?.map((headline) => {
          return (
            <div
              className={classes.headlineLeft}
              key={headline?.uri}
              onClick={() => {
                navigate(
                  `/home/${headline.uri}/${headline?.headline
                    ?.replaceAll(" ", "-")
                    .toLowerCase()}`
                );
                scrollToTop();
              }}
            >
              <div className={classes.imageSection}>
                <img src={headline?.picture} alt={headline?.headline} />
                <div className={classes.tag}>{headline?.subject[1]?.name}</div>
              </div>
              <div className={classes.description}>
                <div>
                  {headline?.headline?.length > 70
                    ? `${headline?.headline?.slice(0, 70)}...`
                    : headline?.headline}
                </div>
                <div>
                  {headline?.description_text?.length > 90
                    ? `${headline?.description_text?.slice(0, 90)}...`
                    : headline?.description_text}
                </div>
                <a
                  href={`/home/${headline.uri}/${headline?.headline
                    ?.replaceAll(" ", "-")
                    ?.toLowerCase()}`}
                >
                  Continue Reading
                </a>
              </div>
            </div>
          );
        })}
      </div>
      <div className={classes.homeStoriesRight}>
        {topStories?.slice(3, 4)?.map((headline) => {
          return (
            <div
              className={classes.headlineRight}
              key={headline?.uri}
              onClick={() => {
                navigate(
                  `/home/${headline.uri}/${headline?.headline
                    ?.replaceAll(" ", "-")
                    .toLowerCase()}`
                );
                scrollToTop();
              }}
            >
              <div className={classes.imageSection}>
                <img src={headline?.picture} alt={headline?.headline} />
              </div>
              <div className={classes.description}>
                <div>
                  {"By SportsDM"} • {moment(headline.firstcreated).fromNow()}
                </div>
                <div>
                  {headline?.headline?.length > 65
                    ? `${headline?.headline?.slice(0, 65)}...`
                    : headline?.headline}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HomeTopStories;
