import classes from "./HomeFeaturedStoriesAndLiveScores.module.css";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "../../Components/Card/Card";
import HomeFeaturedStories from "./HomeFeaturedStories";
import HomeLiveMatch from "./HomeLiveMatch";
import AdComponent from "../../Components/AdComponent/AdComponent";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../Utilities/scrollToTop";
import { useContext } from "react";
import { AdContext } from "../../Context/AdContext";

const HomeFeaturedStoriesAndLiveScores = () => {
  // Navigation
  const navigate = useNavigate();

  //  Context
  const { homePageAds } = useContext(AdContext);
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.topStoriesHeader}>
          <div>Featured Stories</div>
        </div>
        <div className={classes.breakingNewsHeader}>
          <div
            onClick={() => {
              navigate("/fixtures");
              scrollToTop();
            }}
          >
            Live Scores
          </div>
          <div className={classes.navIcon}>
            <FontAwesomeIcon icon={faAngleRight} />
          </div>
        </div>
      </div>

      {/* Card Components */}
      <div className={classes.newsContainer}>
        <Card>
          <div className={classes.newsContainerInner}>
            <div className={classes.topStories}>
              <HomeFeaturedStories />
            </div>
            <div className={classes.newsContainerRight}>
              <div>
                <HomeLiveMatch />
              </div>
              <div className={classes.adContainer}>
                <div>
                  <AdComponent
                    adArray={homePageAds}
                    section="300px-by-250px-live-score-section-ad-1"
                  />
                </div>

                <div>
                  <AdComponent
                    adArray={homePageAds}
                    section="300px-by-250px-live-score-section-ad-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>

      <div className={classes.mobileNewsContainer}>
        <div className={`${classes.header} ${classes.mobileSecondHeader}`}>
          <div className={classes.topStoriesHeader}>
            <div>Live Scores</div>
            <div className={classes.navIcon}>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </div>
        </div>
        <Card>
          <div className={classes.newsContainerInner}>
            <HomeLiveMatch />
          </div>
          <div className={classes.adContainer}>
            <AdComponent width="244px" height="484px" />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default HomeFeaturedStoriesAndLiveScores;
