import React from "react";
import classes from "./HomeDateSection.module.css";

const HomeDateSection = (props) => {
  // Utilities
  function getFormattedDate(date) {
    // Define an array of weekday names
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    // Define an array of month names
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    // Get the weekday, month, and day of the month from the date object
    const weekday = weekdays[date.getDay()];
    const month = months[date.getMonth()];
    const day = date.getDate();
    // Define a function to get the ordinal suffix for the day of the month
    function getOrdinalSuffix(day) {
      if (day >= 11 && day <= 13) {
        return "th";
      } else if (day % 10 === 1) {
        return "st";
      } else if (day % 10 === 2) {
        return "nd";
      } else if (day % 10 === 3) {
        return "rd";
      } else {
        return "th";
      }
    }
    // Get the ordinal suffix for the day of the month
    const ordinalSuffix = getOrdinalSuffix(day);
    // Return the formatted date string
    return `${
      props.showDate ? `${weekday},` : ""
    } ${day}${ordinalSuffix} ${month} ${date.getFullYear()}`;
  }

  const todaysDate = new Date();

  return (
    <div className={classes.container}>{getFormattedDate(todaysDate)}</div>
  );
};

export default HomeDateSection;
