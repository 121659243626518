import React from "react";
import classes from "./HomeBreakingNews.module.css";
import { useContext } from "react";
import { AppContext } from "../../Context/AppContext";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../Utilities/scrollToTop";

const HomeBreakingNews = () => {
  // context
  const { headlines } = useContext(AppContext);

  // Navigate
  const navigate = useNavigate();
  return (
    <div className={classes.container}>
      {headlines?.slice(0, 4)?.map((headline) => {
        return (
          <div
            className={classes.headline}
            key={headline.uri}
            onClick={() => {
              navigate(
                `/home/${headline.uri}/${headline?.headline
                  ?.replaceAll(" ", "-")
                  .toLowerCase()}`
              );
              scrollToTop();
            }}
          >
            <div>{moment(headline.firstcreated).fromNow()}</div>
            <div>{headline.headline}</div>
          </div>
        );
      })}
    </div>
  );
};

export default HomeBreakingNews;
