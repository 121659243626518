import React, { useState, useEffect } from "react";
import axios from "axios";
import { CircularProgress } from "@mui/material";

const TeamLogo = (props) => {
  const [logo, setLogo] = useState(null);

  const fetchImage = async () => {
    // setLogo(null);
    try {
      const response = await axios.get(
        // `https://eapi.enetpulse.com/image/team_logo/?teamFK=${props.id}&username=${enetPulseUsername}&token=${enetPulseTokenId}`
        `https://api.sportdm.com/api/v1/get_teamlogo/${props.id}`
      );
      const imageCode = Object.values(response.data.images)[0].value;
      setLogo(`data:image/png;base64,${imageCode}`);
    } catch (error) {}
  };

  useEffect(() => {
    fetchImage();
    // eslint-disable-next-line
  }, [props.id]);

  if (!logo) {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress
          size="1rem"
          color="inherit"
          style={{ color: "#ffd91b" }}
        />
      </div>
    );
  }

  return (
    <img
      width={"53px"}
      height={"53px"}
      src={logo}
      alt="Logo"
      style={{ cursor: "pointer" }}
    />
  );
};

export default TeamLogo;
