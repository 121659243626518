import { v4 } from "uuid";

export const navItems = [
  {
    id: v4(),
    title: "ALL",
    isActive: false,
    route: "/home",
    external: false,
  },

  {
    id: v4(),
    title: "ARTICLES",
    isActive: false,
    route: "/articles",
    external: false,
  },
  {
    id: v4(),
    title: "AFCON",
    isActive: false,
    route: "https://afcon.sportdm.com",
    external: true,
  },

  {
    id: v4(),
    title: "TRANSFERS",
    isActive: false,
    route: "/transfers",
    external: false,
  },

  {
    id: v4(),
    title: "FIXTURES",
    isActive: false,
    route: "/fixtures",
    external: false,
  },

  // {
  //   id: v4(),
  //   title: "VIDEOS",
  //   isActive: false,
  //   route: "/videos",
  // },

  {
    id: v4(),
    title: "News",
    isActive: false,
    route: "/news-for-you",
    external: false,
  },

  {
    id: v4(),
    title: "Live Scores",
    isActive: false,
    route: "https://scorenownow.com",
    external: true,
  },
];
