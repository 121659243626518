import classes from "./HomeTransferNews.module.css";
import Card from "../../Components/Card/Card";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AdComponent from "../../Components/AdComponent/AdComponent";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../Utilities/scrollToTop";
import HomeChampionsLeagueNewsContainer from "./HomeChampionsLeagueNewsContainer";
import { useContext } from "react";
import { AdContext } from "../../Context/AdContext";

const HomeChampionsLeague = () => {
  // Navigation
  const navigate = useNavigate();

  // Context
  const { homePageAds } = useContext(AdContext);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div
          className={classes.topStoriesHeader}
          onClick={() => {
            navigate("/champions-league");
            scrollToTop();
          }}
        >
          <div>Champions League News</div>
          <div className={classes.navIcon}>
            <FontAwesomeIcon icon={faAngleRight} />
          </div>
        </div>
      </div>

      {/* Card Components */}
      <div className={classes.newsContainer}>
        <Card>
          <div className={classes.newsContainerInner}>
            <div className={classes.topStories}>
              <HomeChampionsLeagueNewsContainer />
            </div>
            <div>
              <AdComponent
                adArray={homePageAds}
                section="160px-by-600px-by-the-champions-league-news-section"
              />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default HomeChampionsLeague;
