import Layout from "../../Components/Layout/Layout";
import { advertise } from "../../Utilities/aboutUsAndTerms";
import classes from "../AboutUs/AboutUs.module.css";

const Advertise = () => {
  return (
    <Layout notDisplaySide>
      <div className={classes.container}>
        <h4>Advertise</h4>
        <div className={classes.privacyNoticeContainer}>
          {advertise.map((data, i) => {
            return (
              <div key={i} className={classes.info}>
                {data.paragraph && <p>{data?.paragraph}</p>}
                {data.children &&
                  data.children.map((datum, j) => {
                    return (
                      <ul key={j}>
                        <p>{datum.description}</p>
                        {data.prechild && <p>{data?.prechild}</p>}
                        {datum.list.map((list, k) => {
                          return <li key={k}>{list}</li>;
                        })}
                      </ul>
                    );
                  })}

                <p>
                  Targeted Reach: Our diverse platforms attract a broad spectrum
                  of sports fans, ensuring your message reaches the right
                  audience.
                </p>
                <p>
                  Engaged Community: We foster a vibrant community where fans
                  connect, share, and debate. Be a part of the conversation and
                  build lasting relationships with your target market.
                </p>
                <p>
                  Creative Solutions: We offer various ad formats and innovative
                  solutions to showcase your brand and resonate with our
                  audience.
                </p>
              </div>
            );
          })}
        </div>
        <div>
          <p className={classes.lastNote}>
            To advertise with us, kindly send a mail to{" "}
            <a href="mailto:admin@thenativesport.com">
              admin@thenativesport.com
            </a>
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default Advertise;
