import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import AdComponent from "../../Components/AdComponent/AdComponent";
import { AdContext } from "../../Context/AdContext";
import classes from "./HomeLightBoxContainer.module.css";

const HomeLightBoxContainer = (props) => {
  // Contex
  const { homePageAds } = useContext(AdContext);

  return (
    <div className={classes.container}>
      <div className={classes.mainContainer}>
        <AdComponent
          adArray={homePageAds}
          section="540px-by-540px-lightbox-popup"
        />
        <div
          className={classes.cancel}
          onClick={() => {
            props.onClick();
          }}
        >
          <FontAwesomeIcon icon={faXmark} />
        </div>
      </div>
      <div className={classes.mobileContainer}>
        <AdComponent
          adArray={homePageAds}
          section="540px-by-540px-lightbox-popup-(mobile)"
        />
        <div
          className={classes.cancel}
          onClick={() => {
            props.onClick();
          }}
        >
          <FontAwesomeIcon icon={faXmark} />
        </div>
      </div>
    </div>
  );
};

export default HomeLightBoxContainer;
