import React, { useRef, useContext, useState } from "react";
import classes from "./Header.module.css";
import sportdmLogo from "../../Assets/Images/sportdmLogo.png";
import { navItems } from "../../Utilities/navItems";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faBars,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import { AppContext } from "../../Context/AppContext";
import { scrollToTop } from "../../Utilities/scrollToTop";

const Header = () => {
  // Context
  const {
    searchQuery,
    setSearchQuery,
    searchHandler,
    displaySearchContainer,
    setDisplaySearchContainer,
  } = useContext(AppContext);

  // State
  const [displayMpreOptions, setDisplayMoreOptions] = useState(false);

  // Ref
  const searchRef = useRef(null);

  // Navigate
  const navigate = useNavigate();

  const openSideMenu = () => {
    document.getElementById("sideMenu").style.width = "100%";
  };

  const closeSideMenu = () => {
    document.getElementById("sideMenu").style.width = "0%";
  };

  return (
    <div className={classes.container}>
      <div className={classes.logoSection}>
        <img
          src={sportdmLogo}
          alt="FlexMag Logo"
          onClick={() => {
            navigate("/home");
          }}
        />
      </div>
      <div className={classes.responsivemenu}>
        <i
          onClick={() => {
            setDisplaySearchContainer((prevState) => {
              return !prevState;
            });
          }}
        >
          <FontAwesomeIcon icon={faSearch} />
        </i>
        <i>
          <FontAwesomeIcon icon={faBars} onClick={openSideMenu} />
        </i>
      </div>
      {/* {!displaySearch && ( */}

      <div className={classes.navItemSection}>
        {navItems.slice(0, 5).map((data, i) => {
          if (data.external) {
            return (
              <a href={data.route} target="_blank" rel="noreferrer" key={i}>
                <div className={classes.navItem}>
                  <div>{data.title}</div>
                  <div>{data.icon}</div>
                </div>
              </a>
            );
          }
          return (
            <Link
              key={data.id}
              to={data.route}
              className={
                window.location.href.includes(data.route)
                  ? `${classes.activeNav}`
                  : undefined
              }
              onClick={() => {
                scrollToTop();
              }}
            >
              <div className={classes.navItem}>
                <div>{data.icon}</div>
                <div>{data.title}</div>
              </div>

              {window.location.href.includes(data.route) && (
                <div className={classes.activeIndicator}></div>
              )}
            </Link>
          );
        })}
        <div
          className={classes.moreSection}
          onClick={() => {
            setDisplayMoreOptions((prevState) => {
              return !prevState;
            });
          }}
        >
          <div>MORE</div>
          <div
            style={
              displayMpreOptions
                ? {
                    transform: "rotate(-180deg)",
                    transition: "all .3s ease-in-out",
                  }
                : {
                    transform: "rotate(0deg)",
                    transition: "all .3s ease-in-out",
                  }
            }
          >
            <FontAwesomeIcon icon={faAngleDown} />
          </div>
          {displayMpreOptions && (
            <div className={classes.moreOptions}>
              {navItems.slice(5).map((data, i) => {
                if (data.external) {
                  return (
                    <a
                      href={data.route}
                      target="_blank"
                      rel="noreferrer"
                      className={
                        window.location.href.includes(data.route)
                          ? `${classes.activeNav}`
                          : undefined
                      }
                      key={i}
                    >
                      <div className={classes.navItem}>
                        <div>{data.icon}</div>
                        <div>{data.title}</div>
                      </div>
                    </a>
                  );
                }
                return (
                  <Link
                    key={data.id}
                    to={data.route}
                    className={
                      window.location.href.includes(data.route)
                        ? `${classes.activeNav}`
                        : undefined
                    }
                    onClick={() => {
                      scrollToTop();
                    }}
                  >
                    <div className={classes.navItem}>
                      <div>{data.icon}</div>
                      <div>{data.title}</div>
                    </div>
                  </Link>
                );
              })}
            </div>
          )}
        </div>
      </div>

      {/* )} */}
      <div className={classes.search} ref={searchRef}>
        <input
          type="search"
          placeholder="Search news & articles "
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          value={searchQuery}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              searchHandler(searchQuery);
            }
          }}
          onFocus={() => {
            navigate("/search");
          }}
        />
        <i>
          <FontAwesomeIcon
            icon={faSearch}
            fontSize="1.2rem"
            onClick={() => {
              navigate("/search");
            }}
          />
        </i>
      </div>

      {/* Side menu */}
      <div id="sideMenu" className={classes.sideNav}>
        <div className={classes.sideNavInner}>
          <div className={classes.header}>
            <img src={sportdmLogo} alt="Sportdm Logo" />
            <button className={classes.btnClose} onClick={closeSideMenu}>
              &times;
            </button>
          </div>

          <div className={classes.sideContainer}>
            {navItems.map((data, i) => {
              return (
                <Link
                  key={data.id}
                  to={data.route}
                  className={
                    window.location.href.includes(data.route)
                      ? `${classes.activeNav}`
                      : undefined
                  }
                >
                  <div className={classes.navItem}>
                    <div>{data.icon}</div>
                    <div>{data.title}</div>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>

      <div
        className={classes.mobileSearchContainer}
        id="mobileSearchContainer"
        style={
          displaySearchContainer
            ? { height: "52px", overflow: "hidden" }
            : { height: "0px", overflow: "hidden" }
        }
      >
        <input
          type="search"
          placeholder="Search news & articles "
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
          value={searchQuery}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              searchHandler(searchQuery);
            }
          }}
          onFocus={() => {
            navigate("/search");
          }}
        />
      </div>
    </div>
  );
};

export default Header;
