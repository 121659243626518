import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import AppContextProvider from "./Context/AppContext";
import MatchesContextProvider from "./Context/MatchesContext";
import AdContextProvider from "./Context/AdContext";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

const helmetContext = {};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AppContextProvider>
    <MatchesContextProvider>
      <AdContextProvider>
        <HelmetProvider context={helmetContext}>
          <React.StrictMode>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </React.StrictMode>
        </HelmetProvider>
      </AdContextProvider>
    </MatchesContextProvider>
  </AppContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
