import Layout from "../../Components/Layout/Layout";
import classes from "./AboutUs.module.css";
import { aboutUs } from "../../Utilities/aboutUsAndTerms";

const AboutUs = () => {
  return (
    <Layout notDisplaySide>
      <div className={classes.container}>
        <h4>SportDm</h4>
        {aboutUs?.map((data, i) => {
          return <p key={i}>{data}</p>;
        })}
      </div>
    </Layout>
  );
};

export default AboutUs;
