import React, { useContext } from "react";
import ReusableArticlesContainer from "../../Components/ReusableArticlesContainer/ReusableArticlesContainer";
import { AppContext } from "../../Context/AppContext";

const BaseballContainer = () => {
  // context
  const { competitionNews, isSendingRequest, fetchAfconNews, setOffsetValue } =
    useContext(AppContext);

  return (
    <div>
      <ReusableArticlesContainer
        articles={competitionNews}
        title="AFCON News"
        isSendingRequest={isSendingRequest}
        fetchFunction={fetchAfconNews}
        setOffset={setOffsetValue}
      />
    </div>
  );
};

export default BaseballContainer;
