// import { Skeleton } from "@mui/material";
import React, { useState, useEffect } from "react";
import classes from "./AdComponent.module.css";

const AdComponent = (props) => {
  // Utils

  const [imagesToBeMapped, setImagesToBeMapped] = useState([]);

  useEffect(() => {
    if (props.adArray?.length > 0) {
      const adsAdrayCopy = props?.adArray
        ?.filter((data) => {
          return data.section === props?.section;
        })
        ?.map((data, i) => {
          if (i === 0) {
            return { ...data, isActive: true };
          }
          return { ...data, isActive: false };
        });

      setImagesToBeMapped(adsAdrayCopy);
    }

    // eslint-disable-next-line
  }, [props.adArray]);

  // const imageChangeHandler = (index) => {
  //   const imagesCopy = [...imagesToBeMapped].map((img, i) => {
  //     if (i === index) {
  //       return {
  //         ...img,
  //         active: true,
  //       };
  //     }
  //     return {
  //       ...img,
  //       active: false,
  //     };
  //   });

  //   setImagesToBeMapped(imagesCopy);
  // };

  const [index, setIndex] = useState(0);

  // Efects
  useEffect(() => {
    const timer = setInterval(() => {
      setIndex(index + 1);
      if (index === imagesToBeMapped?.length - 1) {
        setIndex(0);
      }
      const imagesCopy = [...imagesToBeMapped].map((img, i) => {
        if (i === index) {
          return {
            ...img,
            isActive: true,
          };
        }
        return {
          ...img,
          isActive: false,
        };
      });
      setImagesToBeMapped(imagesCopy);
    }, 10000);

    return () => {
      clearInterval(timer);
    };

    // eslint-disable-next-line
  });

  return (
    <>
      <div className={classes.container}>
        {imagesToBeMapped.length > 0 && (
          <>
            {imagesToBeMapped?.map((img) => {
              return (
                <div
                  className={classes.imageSection}
                  style={{ height: `${img?.height}px` }}
                  key={img.id}
                >
                  <img
                    className={img.isActive ? classes.active : null}
                    src={img.media_url}
                    height={img.height}
                    width={img.width}
                    alt={img.name}
                    key={img.id}
                    style={{
                      width: `${img?.width}px`,
                      height: `${img?.height}px`,
                    }}
                    onClick={() => {
                      window.open(img.redirect_url);
                    }}
                  />
                </div>
              );
            })}
          </>
        )}
      </div>
    </>
  );
};

export default AdComponent;
