import { useContext } from "react";
import { AppContext } from "../../Context/AppContext";
import classes from "./HomeTodayLastYearContainer.module.css";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import { scrollToTop } from "../../Utilities/scrollToTop";

const HomeTodayLastYearContainer = () => {
  // context
  const { thisDayLastYear } = useContext(AppContext);

  // Navigate
  const navigate = useNavigate();
  return (
    <div className={classes.container}>
      <div className={classes.homeStoriesRight}>
        {thisDayLastYear?.slice(0, 1)?.map((headline) => {
          return (
            <div
              className={classes.headlineRight}
              key={headline?.uri}
              onClick={() => {
                navigate(
                  `/home/${headline.uri}/${headline?.headline
                    .replaceAll(" ", "-")
                    .toLowerCase()}`
                );
                scrollToTop();
              }}
            >
              <div className={classes.imageSection}>
                <img src={headline?.picture} alt={headline?.headline} />
              </div>
              <div className={classes.description}>
                <div>
                  {headline?.headline?.length > 50
                    ? `${headline?.headline?.slice(0, 50)}...`
                    : headline?.headline}
                </div>
                <div>
                  {headline?.description_text?.length > 120
                    ? `${headline?.description_text?.slice(0, 120)}...`
                    : headline?.description_text}
                </div>
                <a
                  href={`/home/${headline.uri}/${headline?.headline
                    .replaceAll(" ", "-")
                    .toLowerCase()}`}
                >
                  Continue Reading
                </a>
              </div>
            </div>
          );
        })}
      </div>

      {/* Bottom news */}
      <div className={classes.homeStoriesLeft}>
        {thisDayLastYear?.slice(1, 4)?.map((headline) => {
          return (
            <div
              className={classes.headlineLeft}
              key={headline?.uri}
              onClick={() => {
                navigate(
                  `/home/${headline.uri}/${headline?.headline
                    .replaceAll(" ", "-")
                    .toLowerCase()}`
                );
                scrollToTop();
              }}
            >
              <div className={classes.imageSection}>
                <img src={headline?.picture} alt={headline?.headline} />
              </div>
              <div className={classes.description}>
                <div>{headline?.headline}</div>
                <div>
                  {"By SportsDM"} • {moment(headline.firstcreated).fromNow()}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HomeTodayLastYearContainer;
