import React, { useContext } from "react";
import ReusableArticlesContainer from "../../Components/ReusableArticlesContainer/ReusableArticlesContainer";
import { AppContext } from "../../Context/AppContext";

const WomenSportNewsContainer = () => {
  // context
  const {
    womenSportNews,
    isSendingRequest,
    fetchWomenSportNews,
    setWomenSportNewsOffset,
  } = useContext(AppContext);

  return (
    <div>
      <ReusableArticlesContainer
        articles={womenSportNews}
        title="Women Sport News"
        isSendingRequest={isSendingRequest}
        fetchFunction={fetchWomenSportNews}
        setOffset={setWomenSportNewsOffset}
      />
    </div>
  );
};

export default WomenSportNewsContainer;
