import { CircularProgress } from "@mui/material";
import { useContext } from "react";
import { AppContext } from "../../Context/AppContext";
import classes from "./HomeEmailSIgnupContainer.module.css";
import Error from "../../Components/Error/Error";

const HomeEmailSIgnupContainer = (props) => {
  // Context
  const { emailSUbscribeObject, subsscribeToNewsLetter, email, setEmail } =
    useContext(AppContext);
  return (
    <section className={classes.container}>
      <h4>Subscribe to our newsletter now</h4>
      <p>
        Get early access to new features, our events, fun trivia, and sports
        updates, straight to your inbox every week.
      </p>
      {emailSUbscribeObject?.error && (
        <Error type="processing">{emailSUbscribeObject?.error}</Error>
      )}
      {emailSUbscribeObject?.data && (
        <Error type="success">{emailSUbscribeObject?.data}</Error>
      )}

      <form>
        <label htmlFor="email">Email</label>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="me@xyz.com"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <button
          onClick={(e) => {
            e.preventDefault();
            subsscribeToNewsLetter();
          }}
        >
          {emailSUbscribeObject?.isLoading ? (
            <CircularProgress size="2rem" color="inherit" />
          ) : (
            "Subscribe"
          )}
        </button>

        <p>
          Or click{" "}
          <a
            href="https://chat.whatsapp.com/DyQ0xdTZnlUHgBndpPqsdi"
            target="_blank"
            rel="noreferrer"
          >
            here
          </a>{" "}
          to join our Whatsapp community
        </p>
      </form>
    </section>
  );
};

export default HomeEmailSIgnupContainer;
