import React, { useContext } from "react";
import ReusableArticlesContainer from "../../Components/ReusableArticlesContainer/ReusableArticlesContainer";
import { AppContext } from "../../Context/AppContext";

const BreakingNewsContainer = () => {
  // context
  const { headlines, isSendingRequest, fetchAllHeadlines, setOffsetValue } =
    useContext(AppContext);
  return (
    <div>
      <ReusableArticlesContainer
        articles={headlines}
        title="Breaking News"
        isSendingRequest={isSendingRequest}
        fetchFunction={fetchAllHeadlines}
        setOffset={setOffsetValue}
      />
    </div>
  );
};

export default BreakingNewsContainer;
