import { useContext } from "react";
import { MatchesContext } from "../../Context/MatchesContext";
import classes from "./HomeLiveMatchContainer.module.css";
import TeamLogo from "../../Components/TeamLogo/TeamLogo";
import { CircularProgress } from "@mui/material";

const HomeLiveMatchContainer = () => {
  // Context
  const { allMatchesFromApi, loadingLeagueMatches } =
    useContext(MatchesContext);

  return (
    <>
      {loadingLeagueMatches && allMatchesFromApi.length < 1 ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "400px",
          }}
        >
          <CircularProgress
            size="1rem"
            color="inherit"
            style={{ color: "#ffd91b" }}
          />
        </div>
      ) : !loadingLeagueMatches && allMatchesFromApi.length < 1 ? (
        <div className={classes.noNews}>No matches currently available</div>
      ) : (
        <div className={classes.container}>
          {allMatchesFromApi?.slice(0, 2)?.map((data) => {
            return (
              <div
                className={classes.match}
                key={data.id}
                onClick={() => {
                  window.open(
                    `https://scorenownow.com/scores/${data.id}/summary`
                  );
                }}
              >
                <div>
                  <TeamLogo
                    id={
                      Object.values(data?.event_participants)[0]?.participantFK
                    }
                  />
                  <span>{data?.name?.split("-")[0]}</span>
                </div>

                <div>
                  <div>{data?.tournament_stage_name}</div>
                  <div>
                    {data && (
                      <>
                        {" "}
                        <div>{`${
                          Object.values(
                            Object.values(data?.event_participants)[0]?.result
                          )[1]?.value || "-"
                        } : ${
                          Object.values(
                            Object.values(data?.event_participants)[1]?.result
                          )[1]?.value || "-"
                        }`}</div>
                        {/* {firstParticipantScopeResults.length > 0 &&
                      secondParticipantScopeResults.length > 0 && (
                        <div
                          className={classes.aggregateScore}
                        >{`Aggregate (${firstParticipantScopeResults[0]?.value} : ${secondParticipantScopeResults[0]?.value})`}</div>
                      )} */}
                      </>
                    )}
                  </div>
                  <div>
                    {data?.status_type === "inprogress" && (
                      <span className={classes.liveIcon}></span>
                    )}
                    {data && (
                      <span className={classes.matchStatus}>
                        {data?.status_type && (
                          <div>{data?.status_type === "finished" && "FT"}</div>
                        )}
                        {data?.status_type === "inprogress" ? (
                          <div>
                            {/* {`${Object.values(specificMatchData?.elapsed)[0]?.elapsed}'`} */}

                            {`${Object.values(data?.elapsed)[0]?.elapsed}${
                              Object.values(data?.elapsed)[0]
                                ?.injury_time_elapsed > 0
                                ? `+${
                                    Object.values(data?.elapsed)[0]
                                      ?.injury_time_elapsed
                                  }`
                                : ""
                            }'`}
                          </div>
                        ) : (
                          <div className={classes.matchTime}>
                            {data?.startdate.slice(11, 16)}
                          </div>
                        )}
                      </span>
                    )}
                  </div>
                </div>
                <div>
                  {data && (
                    <div>
                      <TeamLogo
                        id={
                          Object.values(data?.event_participants)[1]
                            ?.participantFK
                        }
                      />
                    </div>
                  )}
                  <span>{data?.name?.split("-")[1]}</span>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default HomeLiveMatchContainer;
