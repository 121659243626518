import React from "react";
import classes from "./HomeBreakingNews.module.css";
import { useContext } from "react";
import { AppContext } from "../../Context/AppContext";
import moment from "moment/moment";

const HomeBaseballContainer = () => {
  // context
  const { headlines } = useContext(AppContext);

  return (
    <div className={classes.container}>
      {headlines?.slice(0, 4)?.map((headline) => {
        return (
          <a
            className={classes.headline}
            key={headline.uri}
            href={`https://afcon.sportdm.com/home/${
              headline.uri
            }/${headline?.headline.replaceAll(" ", "-").toLowerCase()}`}
            target="_blank"
            rel="noreferrer"
          >
            <div>{moment(headline.firstcreated).fromNow()}</div>
            <div>{headline.headline}</div>
          </a>
        );
      })}
    </div>
  );
};

export default HomeBaseballContainer;
