import { useContext } from "react";
import { AppContext } from "../../Context/AppContext";
import classes from "./HomeTopStories.module.css";
import moment from "moment/moment";

const HomeCompetition = () => {
  // context
  const { competitionNews } = useContext(AppContext);

  return (
    <div className={classes.container}>
      <div className={classes.homeStoriesLeft}>
        {competitionNews?.slice(0, 3)?.map((headline) => {
          return (
            <a
              className={classes.headlineLeft}
              key={headline?.uri}
              href={`https://afcon.sportdm.com/home/${
                headline.uri
              }/${headline?.headline.replaceAll(" ", "-").toLowerCase()}`}
              target="_blank"
              rel="noreferrer"
            >
              <div className={classes.imageSection}>
                <img
                  src={
                    headline?.picture ||
                    headline?.associations?.featureimage?.renditions["1x1"]
                      ?.href
                  }
                  alt={headline?.headline}
                />
                <div className={classes.tag}>{headline?.subject[1]?.name}</div>
              </div>
              <div className={classes.description}>
                <div>
                  {headline?.headline?.length > 70
                    ? `${headline?.headline?.slice(0, 70)}...`
                    : headline?.headline}
                </div>
                <div>
                  {headline?.description_text?.length > 90
                    ? `${headline?.description_text?.slice(0, 90)}...`
                    : headline?.description_text}
                </div>
                <a
                  href={`https://afcon.sportdm.com/home/${
                    headline.uri
                  }/${headline?.headline.replaceAll(" ", "-").toLowerCase()}`}
                >
                  Continue Reading
                </a>
              </div>
            </a>
          );
        })}
      </div>
      <div className={classes.homeStoriesRight}>
        {competitionNews?.slice(3, 4)?.map((headline) => {
          return (
            <a
              className={classes.headlineRight}
              key={headline?.uri}
              href={`https://afcon.sportdm.com/home/${
                headline.uri
              }/${headline?.headline.replaceAll(" ", "-").toLowerCase()}`}
              target="_blank"
              rel="noreferrer"
            >
              <div className={classes.imageSection}>
                <img
                  src={
                    headline?.picture ||
                    headline?.picture ||
                    headline?.associations?.featureimage?.renditions["1x1"]
                      ?.href
                  }
                  alt={headline?.headline}
                />
              </div>
              <div className={classes.description}>
                <div>
                  {"By SportsDM"} • {moment(headline.firstcreated).fromNow()}
                </div>
                <div>
                  {headline?.headline?.length > 65
                    ? `${headline?.headline?.slice(0, 65)}...`
                    : headline?.headline}
                </div>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default HomeCompetition;
