import React, { useContext } from "react";
import ReusableArticlesContainer from "../../Components/ReusableArticlesContainer/ReusableArticlesContainer";
import { AppContext } from "../../Context/AppContext";

const GolfContainer = () => {
  // context
  const { competitionNews, isSendingRequest, fetchAfconNews } =
    useContext(AppContext);

  return (
    <div>
      <ReusableArticlesContainer
        articles={competitionNews}
        title="AFCON News"
        isSendingRequest={isSendingRequest}
        fetchFunction={fetchAfconNews}
        external={true}
      />
    </div>
  );
};

export default GolfContainer;
