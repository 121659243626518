import React from "react";
import Layout from "../Layout/Layout";
import classes from "./ReusableArticlesContainer.module.css";
import moment from "moment/moment";
import Card from "../../Components/Card/Card";
import AdComponent from "../../Components/AdComponent/AdComponent";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ReusableArticlesContainer = (props) => {
  // Navigate
  const navigate = useNavigate();

  useEffect(() => {
    props.fetchFunction();

    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <section className={classes.container}>
        <div className={classes.header}>
          <div
            className={classes.nav}
            onClick={() => {
              navigate(-1);
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </div>
          <div>{props.title}</div>
        </div>
        {props.isSendingRequest ? (
          <div className={classes.loading}>
            <CircularProgress
              size="1rem"
              color="inherit"
              style={{ color: "#ffd91b" }}
            />
          </div>
        ) : props.articles.length < 1 ? (
          <div className={classes.newsContainer}>
            <Card>
              <div className={classes.noNews}>No more news available</div>
            </Card>
          </div>
        ) : (
          <div className={classes.newsContainer}>
            <Card>
              <div className={classes.newsContainerInner}>
                <div className={classes.homeStoriesLeft}>
                  <div className={classes.homeStoriesLeftTop}>
                    {props.articles?.slice(0, 1)?.map((headline) => {
                      return (
                        <div
                          className={classes.headlineRight}
                          key={props.isCreated ? headline?.id : headline?.uri}
                          onClick={() => {
                            if (!props.external) {
                              if (props.isCreated) {
                                navigate(
                                  `/home/created-for-you/${headline.id}/${headline?.slug}`
                                );
                              } else {
                                navigate(
                                  `/home/${headline.uri}/${headline?.headline
                                    ?.replaceAll(" ", "-")
                                    .toLowerCase()}`
                                );
                              }
                            } else {
                              window.open(
                                `http://afcon.sportdm.com/home/${
                                  headline?.uri
                                }/${headline?.headline
                                  ?.replaceAll(" ", "-")
                                  .toLowerCase()}`
                              );
                            }
                          }}
                        >
                          <div className={classes.imageSection}>
                            <img
                              src={
                                props?.isCreated
                                  ? headline.image[0]
                                  : headline?.picture ||
                                    headline?.associations?.featureimage
                                      ?.renditions["1x1"]?.href
                              }
                              alt={
                                props?.isCreated
                                  ? headline.title
                                  : headline?.headline
                              }
                            />
                          </div>
                          <div className={classes.description}>
                            <div>
                              {props.isCreated
                                ? headline?.title
                                : headline?.headline}
                            </div>
                            <div>
                              {"By SportsDM"} •{" "}
                              {props.isCreated
                                ? moment(headline?.updated_at).fromNow()
                                : moment(headline.firstcreated).fromNow()}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className={classes.homeStoriesLeftBottom}>
                    {props.articles?.slice(1, 3)?.map((headline) => {
                      return (
                        <div
                          className={classes.headlineRight}
                          key={props?.isCreated ? headline.id : headline?.uri}
                          onClick={() => {
                            if (!props.external) {
                              if (props.isCreated) {
                                navigate(
                                  `/home/created-for-you/${headline.id}/${headline.slug}`
                                );
                              } else {
                                navigate(
                                  `/home/${headline?.uri}/${headline?.headline
                                    ?.replaceAll(" ", "-")
                                    .toLowerCase()}`
                                );
                              }
                            } else {
                              window.open(
                                `http://afcon.sportdm.com/home/${
                                  headline?.uri
                                }/${headline?.headline
                                  ?.replaceAll(" ", "-")
                                  .toLowerCase()}`
                              );
                            }
                          }}
                        >
                          <div className={classes.imageSection}>
                            <img
                              src={
                                props?.isCreated
                                  ? headline.image[0]
                                  : headline?.picture ||
                                    headline?.associations?.featureimage
                                      ?.renditions["1x1"]?.href
                              }
                              alt={
                                props?.isCreated
                                  ? headline.title
                                  : headline?.headline
                              }
                            />
                          </div>
                          <div className={classes.description}>
                            <div>{headline?.headline || headline?.title}</div>
                            <div>
                              {"By SportsDM"} •{" "}
                              {props.isCreated
                                ? moment(headline?.updated_at).fromNow()
                                : moment(headline.firstcreated).fromNow()}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className={classes.homeStoriesRight}>
                  {props.articles.length > 2 &&
                    props.articles?.slice(3, 5)?.map((headline) => {
                      return (
                        <div
                          className={classes.headlineRight}
                          key={props?.isCreated ? headline.id : headline?.uri}
                          onClick={() => {
                            if (!props.external) {
                              if (props.isCreated) {
                                navigate(
                                  `/home/created-for-you/${headline.id}/${headline?.slug}`
                                );
                              } else {
                                navigate(
                                  `/home/${headline?.uri}/${headline?.headline
                                    ?.replaceAll(" ", "-")
                                    .toLowerCase()}`
                                );
                              }
                            } else {
                              window.open(
                                `http://afcon.sportdm.com/home/${
                                  headline?.uri
                                }/${headline?.headline
                                  ?.replaceAll(" ", "-")
                                  .toLowerCase()}`
                              );
                            }
                          }}
                        >
                          <div className={classes.imageSection}>
                            <img
                              src={
                                props?.isCreated
                                  ? headline.image[0]
                                  : headline?.picture ||
                                    headline?.associations?.featureimage
                                      ?.renditions["1x1"]?.href
                              }
                              alt={
                                props?.isCreated
                                  ? headline.title
                                  : headline?.headline
                              }
                            />
                          </div>
                          <div className={classes.description}>
                            <div>{headline?.headline || headline?.title}</div>
                            <div>
                              {"By SportsDM"} •{" "}
                              {props.isCreated
                                ? moment(headline?.updated_at).fromNow()
                                : moment(headline.firstcreated).fromNow()}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className={classes.homeStoriesBottomBottom}>
                {props.articles.length > 4 && (
                  <div className={classes.homeStoriesBottomBottomLeft}>
                    {props.articles?.slice(5)?.map((headline) => {
                      return (
                        <div
                          className={classes.headlineRight}
                          key={props?.isCreated ? headline.id : headline?.uri}
                          onClick={() => {
                            if (
                              !props.external ||
                              props.external === undefined
                            ) {
                              if (props.isCreated) {
                                navigate(
                                  `/home/created-for-you/${headline.id}/${headline?.slug}`
                                );
                              } else {
                                navigate(
                                  `/home/${headline.uri}/${headline?.headline
                                    ?.replaceAll(" ", "-")
                                    .toLowerCase()}`
                                );
                              }
                            } else {
                              window.open(
                                `http://afcon.sportdm.com/home/${
                                  headline?.uri
                                }/${headline?.headline
                                  ?.replaceAll(" ", "-")
                                  .toLowerCase()}`
                              );
                            }
                          }}
                        >
                          <div className={classes.imageSection}>
                            <img
                              src={
                                props?.isCreated
                                  ? headline.image
                                  : headline?.picture ||
                                    headline?.associations?.featureimage
                                      ?.renditions["1x1"]?.href
                              }
                              alt={
                                props?.isCreated
                                  ? headline.title
                                  : headline?.headline
                              }
                            />
                          </div>
                          <div className={classes.description}>
                            <div>{headline?.headline || headline?.title}</div>
                            <div>
                              {"By SportsDM"} •{" "}
                              {props.isCreated
                                ? "some minutes ago"
                                : moment(headline.firstcreated).fromNow()}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    {props.setOffset && props?.articles?.length === 20 && (
                      <div
                        className={classes.readMore}
                        onClick={() => {
                          props.setOffset((prevState) => prevState + 10);
                        }}
                      >
                        Read more
                      </div>
                    )}
                  </div>
                )}
                <div className={classes.homeStoriesBottomBottomRight}>
                  <div>
                    <AdComponent height="484px" width="244px" />
                  </div>

                  <div>
                    <AdComponent height="484px" width="244px" />
                  </div>
                </div>
              </div>
            </Card>
          </div>
        )}
      </section>
    </Layout>
  );
};

export default ReusableArticlesContainer;
