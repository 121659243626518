import classes from "./MoreLikeThisContainer.module.css";
import Card from "../../Components/Card/Card";
import MoreLikeThisNewsContainer from "./MoreLikeThisNewsContainer";
import { CircularProgress } from "@mui/material";

const MoreLikeThisContainer = ({ moreLikeThis }) => {
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.topStoriesHeader}>
          <div>You Might Like</div>
        </div>
      </div>

      {moreLikeThis?.length < 1 ? (
        <div className={classes.loading}>
          <CircularProgress
            size="1rem"
            color="inherit"
            style={{ color: "#ffd91b" }}
          />
        </div>
      ) : (
        <div className={classes.newsContainer}>
          <Card>
            <div className={classes.newsContainerInner}>
              <div className={classes.topStories}>
                <MoreLikeThisNewsContainer moreLikeThisItems={moreLikeThis} />
              </div>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};

export default MoreLikeThisContainer;
