import Layout from "../../Components/Layout/Layout";
import { AppContext } from "../../Context/AppContext";
import classes from "./TransfersSection.module.css";
import moment from "moment/moment";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Card from "../../Components/Card/Card";
import AdComponent from "../../Components/AdComponent/AdComponent";
import { CircularProgress } from "@mui/material";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TransfersSection = () => {
  // contexts
  const {
    fetchTransferNews,
    transferNews,
    isSendingRequest,
    // setTransferNewsOffsetValue,
  } = useContext(AppContext);

  // effects
  useEffect(() => {
    fetchTransferNews();

    // eslint-disable-next-line
  }, []);

  //   Navigate
  const navigate = useNavigate();
  return (
    <Layout>
      <section className={classes.container}>
        <div className={classes.header}>
          <div
            className={classes.nav}
            onClick={() => {
              navigate(-1);
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </div>
          <div>Transfers</div>
        </div>
        {isSendingRequest ? (
          <div className={classes.loading}>
            <CircularProgress
              size="1rem"
              color="inherit"
              style={{ color: "#ffd91b" }}
            />
          </div>
        ) : (
          <div className={classes.newsContainer}>
            <Card>
              <div className={classes.newsContainerInner}>
                <div className={classes.homeStoriesLeft}>
                  <div className={classes.homeStoriesLeftTop}>
                    {transferNews?.slice(0, 1)?.map((headline) => {
                      return (
                        <div
                          className={classes.headlineRight}
                          key={headline?.uri}
                          onClick={() => {
                            navigate(`/home/${headline.uri}`);
                          }}
                        >
                          <div className={classes.imageSection}>
                            <img
                              src={headline?.picture}
                              alt={headline?.headline}
                            />
                          </div>
                          <div className={classes.description}>
                            <div>{headline?.headline}</div>
                            <div>
                              {"By SportsDM"} •{" "}
                              {moment(headline.firstcreated).fromNow()}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className={classes.homeStoriesLeftBottom}>
                    {transferNews?.slice(1, 3)?.map((headline) => {
                      return (
                        <div
                          className={classes.headlineRight}
                          key={headline?.uri}
                          onClick={() => {
                            navigate(`/home/${headline.uri}`);
                          }}
                        >
                          <div className={classes.imageSection}>
                            <img
                              src={headline?.picture}
                              alt={headline?.headline}
                            />
                          </div>
                          <div className={classes.description}>
                            <div>{headline?.headline}</div>
                            <div>
                              {"By SportsDM"} •{" "}
                              {moment(headline.firstcreated).fromNow()}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className={classes.homeStoriesRight}>
                  {transferNews?.slice(3, 5)?.map((headline) => {
                    return (
                      <div
                        className={classes.headlineRight}
                        key={headline?.uri}
                        onClick={() => {
                          navigate(`/home/${headline.uri}`);
                        }}
                      >
                        <div className={classes.imageSection}>
                          <img
                            src={headline?.picture}
                            alt={headline?.headline}
                          />
                        </div>
                        <div className={classes.description}>
                          <div>{headline?.headline}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className={classes.homeStoriesBottomBottom}>
                <div className={classes.homeStoriesBottomBottomLeft}>
                  {transferNews?.slice(5)?.map((headline) => {
                    return (
                      <div
                        className={classes.headlineRight}
                        key={headline?.uri}
                        onClick={() => {
                          navigate(`/home/${headline.uri}`);
                        }}
                      >
                        <div className={classes.imageSection}>
                          <img
                            src={headline?.picture}
                            alt={headline?.headline}
                          />
                        </div>
                        <div className={classes.description}>
                          <div>{headline?.headline}</div>
                          <div>
                            {"By SportsDM"} •
                            {moment(headline.firstcreated).fromNow()}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {/* <div
                    className={classes.readMore}
                    onClick={() => {
                      setTransferNewsOffsetValue((prevState) => prevState + 10);
                    }}
                  >
                    Read more
                  </div> */}
                </div>
                <div className={classes.homeStoriesBottomBottomRight}>
                  <div>
                    <AdComponent height="484px" width="244px" />
                  </div>

                  <div>
                    <AdComponent height="484px" width="244px" />
                  </div>
                </div>
              </div>
            </Card>
          </div>
        )}
      </section>
    </Layout>
  );
};

export default TransfersSection;
