import classes from "./HomeTodayLastYear.module.css";
import HomeTodayLastYearContainer from "./HomeTodayLastYearContainer";
import Card from "../../Components/Card/Card";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

const HomeTodayLastYear = () => {
  // navigate
  const navigate = useNavigate();

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div
          className={classes.topStoriesHeader}
          onClick={() => {
            navigate("/home/this-day-last-year");
          }}
        >
          <div> Stories from this day Last Year</div>
          <div className={classes.navIcon}>
            <FontAwesomeIcon icon={faAngleRight} />
          </div>
        </div>
      </div>
      <div className={classes.newsContainer}>
        <Card>
          <div className={classes.newsContainerInner}>
            <div className={classes.topStories}>
              <HomeTodayLastYearContainer />
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default HomeTodayLastYear;
